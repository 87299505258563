<script setup lang="ts">
interface Label {
  family: string
  name: string[]
  category: string
}

interface Selected {
  name?: string
  pics?: string
}
interface Body {
  label: Label[]
  selected: Selected[]
}
const emits = defineEmits(['select'])

defineProps<Body>()
</script>

<template>
  <div class="border-2 border-blue-jeans p-5 max-w-lg w-full h-auto rounded-lg bg-indigo-night">
    <p class="font-semibold text-red-500 text-center">
      *** Click to select, click again to deselect ***
    </p>
    <div v-for="(item, index) in label" :key="index" class="mb-3">
      <p class="mb-1">
        {{ item.family }}
      </p>
      <div class="grid grid-cols-3 md:grid-cols-6 justify-between">
        <div v-for="(name, index) in item.name" :key="index" class="flex">
          <button class="flex flex-col items-center text-xs hover:border-2 hover:border-blue-jeans hover:rounded-lg"
            :class="selected.some(select => select.name === item.name[index])
        ? 'border-2 border-blue-jeans rounded-lg'
        : ''
      " @click="emits('select', item.name[index], item.category)">
            <img :src="`https://download.playermon.com/img/playermons/${name.toLowerCase()}.png`"
              class="mx-auto h-18 w-18" />
            {{ name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
