<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable camelcase -->
<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import moment from 'moment-timezone'
import { BigNumber } from 'ethers'
import useTestnet from '~/composables/useTestnet'

interface Playermon {
  playermon_id: number
  price: number
  image: string
  class: string
  head?: string
  tail?: string
  body?: string
  arm?: string
  eye?: string
  chest?: string
  leg?: string
  timestamp?: number
}

interface MarketplaceResponseApi {
  status: number
  data: {
    marketplace_items: Playermon[]
    total_pages: number
    total_items: number
  } | null
}

const volume = ref<number | null>(null)
const totalSales = ref<number | null>(null)
const floorPrice = ref<number | null>(null)
const totalSupply = ref<string | null>(null)
const totalOwners = ref<string | null>(null)
const uniqueOwnersPercentage = ref<string | null>(null)
const selectedPeriod = ref('all')
const page = ref<number>(1)
const limit = ref<number>(10)
const keyword = ref<string>('')

const polygonBlockchainStore = usePolygonBlockchainStore()
const { tokenPrices } = storeToRefs(polygonBlockchainStore)
const { convertToDollar } = useFractionConverter()

const {
  apiUrl,
  imageUrl
} = useTestnet()

// Define a ref variable to store the recent sales data
const recentSales = ref<any[]>([])
const tokenHolders = ref<any[]>([])

const generalSorting = [
  { name: 'All', value: 'all' },
  { name: '24 Hours', value: '24h' },
  { name: '7 Days', value: '7d' },
  { name: '30 Days', value: '30d' },
  { name: '90 Days', value: '90d' }
]

const currentSort = ref({ name: 'Please select a period', value: 'all' })

const fetchWithDateFilter = async () => {
  let dateFrom = '2020-01-01T00:00:00.000Z'
  let dateTo = new Date().toISOString()

  // Set the date range based on selectedPeriod value
  switch (selectedPeriod.value) {
    case '':
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
    case 'all':
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
    case '24h':
      dateFrom = new Date(Date.now() - (24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '7d':
      dateFrom = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '30d':
      dateFrom = new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    case '90d':
      dateFrom = new Date(Date.now() - (90 * 24 * 60 * 60 * 1000)).toISOString()
      dateTo = new Date().toISOString()
      break
    default:
      // Fetch all data
      dateFrom = '2020-01-01T00:00:00.000Z'
      dateTo = new Date().toISOString()
      break
  }

  try {
    // Fetch saleStats and recent sales data
    const statsApiUrl = `${apiUrl}/playermon-sale-history/getSaleStatistics?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}&period=${encodeURIComponent(selectedPeriod.value)}`
    const recentSalesApiUrl = `${apiUrl}/marketplace-v3/getRecentSold?page=${encodeURIComponent(page.value)}&limit=${encodeURIComponent(limit.value)}&keyword=${encodeURIComponent(keyword.value)}`

    const [saleStatsResponse, recentSalesResponse] = await Promise.all([
      fetch(statsApiUrl),
      fetch(recentSalesApiUrl)
    ])

    const saleStatsData = await saleStatsResponse.json()
    const recentSalesData = await recentSalesResponse.json()

    if (saleStatsData.data.length === 0) {
      // Set the values to 0 if saleStatsData is empty
      volume.value = 0
      totalSales.value = 0
    } else {
      const { trading_volume, sales } = saleStatsData.data[0]
      volume.value = Math.round(trading_volume)
      totalSales.value = sales
    }

    const filteredSales = recentSalesData.data.docs.filter((sale: { timestamp: number }) => {
      // Convert timestamp to milliseconds if needed
      const convertedTimestamp = sale.timestamp * (sale.timestamp.toString().length === 10 ? 1000 : 1)

      // Compare with parsed dateFrom and dateTo
      return convertedTimestamp >= Date.parse(dateFrom) && convertedTimestamp <= Date.parse(dateTo)
    })

    // Fetch playermon data for each sale
    const playermonDataPromises = filteredSales.map((sale: { token_id: string }) =>
      fetch(`https://metadata.playermon.com/playermon/${sale.token_id}`)
    )

    const playermonResponses = await Promise.all(playermonDataPromises)
    const playermonData = await Promise.all(playermonResponses.map(response => response.json()))

    const formattedSales = filteredSales.map((sale: any, index: number) => {
      const { image } = playermonData[index]
      const formattedImage = image.replace('ipfs://', 'https://download.playermon.com/playermons/') +
    '.png'

      return {
        ...sale,
        formattedImage
      }
    })

    recentSales.value = formattedSales.length === 0 ? [] : formattedSales
  } catch (error) {
    console.error('Error fetching saleStats and recent sales:', error) // eslint-disable-line no-console
  }
}

const fetchWithoutFilter = async () => {
  try {
    const dateFrom = '2022-01-01T00:00:00.000Z'
    const dateTo = new Date().toISOString()

    // Fetch token holders data
    const tokenHoldersApiUrl = `${apiUrl}/marketplace/getTokenHolders`
    const floorPriceUrl = `${apiUrl}/marketplace/getPlayermonsFloorPrice?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}`

    const tokenHoldersResponse = await fetch(tokenHoldersApiUrl)
    const floorPriceResponse = await fetch(floorPriceUrl)

    const tokenHoldersData = await tokenHoldersResponse.json()
    const floorPriceData = await floorPriceResponse.json()

    const { floor_price } = floorPriceData.data[0]
    floorPrice.value = floor_price

    tokenHolders.value = tokenHoldersData.data.token_holder.holders_with_percentage
    totalSupply.value = tokenHoldersData.data.token_holder.total_supply
  } catch (error) {
    console.error('Error fetching token holders:', error) // eslint-disable-line no-console
  }
}

const fetchTokenHolders = async () => {
  const tokenHoldersUrl = `${apiUrl}/marketplace/getTokenHolders`

  try {
    const [tokenHoldersResponse] = await Promise.all([
      fetch(tokenHoldersUrl)
    ])

    const tokenHoldersData = await tokenHoldersResponse.json()

    const { total_owners, percentage_unique_owners } = tokenHoldersData.data.token_holder
    totalOwners.value = `${total_owners}`
    uniqueOwnersPercentage.value = `${Math.round(percentage_unique_owners)}%`
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error)
  }
}

const { data: recentPlayermonList, pending: pendingRecentPlayermon } = await useFetch<MarketplaceResponseApi>(
  () =>
    `${apiUrl}/marketplace?version=1&page=1&limit=10&sort=latest&stages=Adult&stage=Both`,
  { server: false }
)

const formatDate = (timestamp: string | number) => {
  // Convert seconds to milliseconds if needed
  const timeInMs = typeof timestamp === 'string'
    ? parseInt(timestamp) * (timestamp.length === 10 ? 1000 : 1)
    : timestamp * (timestamp.toString().length === 10 ? 1000 : 1)

  return moment(timeInMs).format('DD MMMM YYYY')
}

const calculateDaysAgo = (timestamp: string | number): string => {
  // Convert seconds to milliseconds if needed
  const timeInMs = typeof timestamp === 'string'
    ? Number(timestamp) * (timestamp.length === 10 ? 1000 : 1)
    : timestamp * (timestamp.toString().length === 10 ? 1000 : 1)

  const daysDiff = moment().diff(moment(timeInMs), 'days')
  return `${daysDiff} day${daysDiff !== 1 ? 's' : ''} ago`
}

const getPriceDifference = (currentPrice: string | null, floorPrice: number | null) => {
  if (currentPrice !== null && floorPrice !== null) {
    const currentPriceNum = parseFloat(currentPrice)
    const floorPriceNum = floorPrice

    if (!isNaN(currentPriceNum) && !isNaN(floorPriceNum)) {
      if (currentPriceNum > floorPriceNum) {
        const percentageDifference = ((currentPriceNum - floorPriceNum) / floorPriceNum) * 100
        return `+${percentageDifference.toFixed(2)}% at floor`
      } else if (currentPriceNum < floorPriceNum) {
        const percentageDifference = ((floorPriceNum - currentPriceNum) / floorPriceNum) * 100
        return `-${percentageDifference.toFixed(2)}% at floor`
      } else {
        return 'At floor 0%'
      }
    }
  }

  return 'Data unavailable'
}

const filteredPlayermons = computed(() => {
  if (!recentPlayermonList.value?.data?.marketplace_items) { return [] }

  const playermons = recentPlayermonList.value.data.marketplace_items

  if (selectedPeriod.value === 'all') {
    // Sort by timestamp (latest to oldest)
    return playermons.sort((a, b) => b.timestamp - a.timestamp)
  }

  const now = Date.now()
  let period = 0

  switch (selectedPeriod.value) {
    case '24h':
      period = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
      break
    case '7d':
      period = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
      break
    case '30d':
      period = 30 * 24 * 60 * 60 * 1000 // 30 days in milliseconds
      break
    case '90d':
      period = 90 * 24 * 60 * 60 * 1000 // 90 days in milliseconds
      break
  }

  // Filter by the selected period and sort by timestamp (latest to oldest)
  return playermons
    .filter(item => now - item.timestamp <= period)
    .sort((a, b) => b.timestamp - a.timestamp)
})

const setSort = (sort: { name: string; value: string }) => {
  currentSort.value = sort
  selectedPeriod.value = sort.value
}

// Call the fetch functions on component mount
const fetchData = async () => {
  await fetchWithDateFilter()
  await fetchWithoutFilter()
  await fetchTokenHolders()
}

const getImageUrl = (image: string) => {
  let formattedImage = ''
  formattedImage = image.replace('ipfs://', `${imageUrl}`) + '.png'
  return formattedImage
}

// Watch for changes in selectedPeriod, page, limit, and keyword, and call the fetch functions accordingly
watch([selectedPeriod, page, limit, keyword], async () => {
  await fetchWithDateFilter()
  await fetchWithoutFilter()
})

onMounted(() => {
  fetchData()
})

</script>

<template>
  <div class="mx-10">
    <div class="grid grid-cols-1 gap-4 lg:gap-8 lg:grid-cols-2">
      <div class="grid grid-cols-1 gap-4 lg:gap-8">
        <div class="grid grid-cols-2 gap-4 lg:gap-8">
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Total Supply
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalSupply }}
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Floor Price
            </p>
            <p v-if="floorPrice !== null" class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ (convertToDollar(BigNumber.from(floorPrice * 1000000).mul('1000000000000'), tokenPrices.pymPrice, 8).toLocaleString()) }} USD
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Volume
            </p>
            <p v-if="volume !== null" class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ volume.toLocaleString() }} USD
            </p>
          </div>
          <div class="flex-1 px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Sales
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalSales }}
            </p>
          </div>
        </div>
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500">
            <!-- Recent Sales -->
            <div class="p-5 text-lg font-semibold text-left rounded-t-lg text-white bg-[#05083a]">
              Recent Sales
              <p class="mt-1 text-sm font-normal text-gray-400">
                Browse the latest sales of Playermon NFTs,
                showcasing the transactions and prices of these unique digital collectibles.
              </p>
            </div>

            <!-- Sales List -->
            <div class="bg-[#05083a] rounded-b-lg">
              <!-- If Sales Exist -->
              <div
                v-if="recentSales.length > 0"
                class="overflow-x-hidden max-h-96 rounded-b-lg bg-[#05083a] custom-scrollbar"
              >
                <div
                  v-for="(sale, index) in recentSales"
                  :key="sale.id"
                  class="bg-[#05083a] h-50 sm:h-50"
                >
                  <div class="w-full pb-2 scale-90 md:w-[650px] lg:w-full lg:mt-5 lg:px-3 sm:mt-2 sm:scale-100">
                    <div class="flex w-full overflow-hidden border-2 rounded-lg shadow-2xl border-sky-500 lg:mt-5 bg-[#0c0f3f]">
                      <!-- Playermon Image -->
                      <div class="bg-[#161A69]">
                        <a
                          :href="`/playermon/${sale.token_id}`"
                          target="_blank"
                          class="text-base"
                        >
                          <div class="mt-2 sm:mt-0 lg:w-40 w-28 aspect-w-1 aspect-h-1">
                            <img
                              :src="sale.formattedImage"
                              alt="Playermon Image"
                              class="object-cover w-full h-full scale-75 sm:scale-90"
                            >
                          </div>
                        </a>
                      </div>

                      <!-- Sale Details -->
                      <div class="flex-1 scale-75 lg:mr-10 lg:pl-6 lg:mt-5 md:mt-2 md:ml-4 lg:ml-0 sm:scale-100">
                        <a
                          :href="`/playermon/${sale.token_id}`"
                          target="_blank"
                          class="text-base text-sky-500 hover:text-sky-200"
                        >
                          Playermon #{{ sale.token_id }}
                        </a>
                        <p class="mt-2 mb-2 text-lime-400">
                          {{ getPriceDifference(sale.price, floorPrice) }}
                        </p>
                      </div>

                      <!-- Price and Timestamp -->
                      <div class="flex flex-col pr-6 mt-5 text-xs text-right sm:text-lg">
                        <p class="text-[#FFE182] lg:text-lg sm:text-sm">
                          {{ sale.price.toLocaleString() }} PYM
                        </p>
                        <p class="mb-2 text-lime-400 sm:mb-0">
                          ({{ sale.usd_amount.toLocaleString() }} USD)
                        </p>
                        <p class="text-white lg:mt-4">
                          {{ calculateDaysAgo(sale.timestamp) }}
                        </p>
                        <a
                          :href="`https://polygonscan.com/tx/${sale.id}`"
                          target="_blank"
                          class="text-sky-500 hover:text-sky-200"
                        >
                          <div class="mb-2">
                            <p>({{ formatDate(sale.timestamp) }})</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- No Results -->
              <div v-else class="max-h-50">
                <div class="bg-[#05083a]">
                  <div class="px-6 py-4">
                    <p class="py-10 text-base text-center text-white">
                      No results to be displayed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4 lg:gap-8">
        <!-- Total Owners and Unique Owners Section -->
        <div class="grid grid-cols-2 gap-4 lg:gap-8">
          <div class="flex flex-col px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Total Owners
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ totalOwners }}
            </p>
          </div>
          <div class="flex flex-col px-5 py-3 mb-4 border-2 rounded-lg md:mb-0 border-sky-500 bg-[#05083a]">
            <p class="text-base text-white">
              Unique Owners
            </p>
            <p class="text-xl font-bold lg:text-2xl text-sky-400">
              {{ uniqueOwnersPercentage }}
            </p>
          </div>
        </div>
        <div class="block overflow-x-hidden">
          <div class="block border-2 rounded-lg border-sky-500 overflow:visible">
            <div class="p-5 text-lg font-semibold text-left rounded-t-lg text-white bg-[#05083a]">
              Recent Listings
              <p class="mt-1 text-sm font-normal text-gray-400">
                Browse the latest Playermon NFTs listed,
                showcasing prices of these unique digital collectibles on marketplace.
              </p>
            </div>
            <div class="bg-[#05083a] rounded-b-lg">
              <!-- Listings -->
              <div
                v-if="!pendingRecentPlayermon && filteredPlayermons.length"
                class="overflow-x-hidden max-h-[500px] rounded-b-lg bg-[#05083a] custom-scrollbar"
              >
                <!-- Loop through filtered Playermons -->
                <div
                  v-for="listing in filteredPlayermons"
                  :key="listing.playermon_id"
                  class="bg-[#05083a] h-50 sm:h-50"
                >
                  <div class="w-full pb-2 scale-90 md:w-[650px] lg:w-full lg:mt-5 lg:px-3 sm:mt-2 sm:scale-100">
                    <div class="flex w-full overflow-hidden border-2 rounded-lg shadow-2xl border-sky-500 lg:mt-5 bg-[#0c0f3f]">
                      <!-- Image -->
                      <div class="bg-[#161A69]">
                        <a :href="`/playermon/${listing.playermon_id}`" target="_blank" class="text-base">
                          <div class="mt-2 sm:mt-0 lg:w-40 w-28 aspect-w-1 aspect-h-1">
                            <img
                              :src="getImageUrl(listing.image)"
                              alt="Playermon Image"
                              class="object-cover w-full h-full scale-75 sm:scale-90"
                            >
                          </div>
                        </a>
                      </div>

                      <!-- Details -->
                      <div class="flex-1 scale-75 lg:mr-10 lg:pl-6 lg:mt-5 md:mt-2 md:ml-4 lg:ml-0 sm:scale-100">
                        <a
                          :href="`/playermon/${listing.playermon_id}`"
                          target="_blank"
                          class="text-base text-sky-500 hover:text-sky-200"
                        >
                          Playermon #{{ listing.playermon_id }}
                        </a>
                        <p class="mt-2 text-lime-400">
                          {{ getPriceDifference(listing.price.toString(), floorPrice) }}
                        </p>
                      </div>

                      <!-- Price and Timestamp -->
                      <div class="flex flex-col pr-6 mt-5 text-xs text-right sm:text-lg">
                        <p class="text-[#FFE182] lg:text-lg sm:text-sm">
                          {{ listing.price.toLocaleString() }} PYM
                        </p>
                        <p class="mb-2 text-lime-400 sm:mb-0">
                          ({{ convertToDollar(BigNumber.from(listing.price * 1000000).mul('1000000000000'), tokenPrices.pymPrice, 8).toLocaleString() }} USD)
                        </p>
                        <p v-if="listing.timestamp" class="text-white lg:mt-4">
                          {{ calculateDaysAgo(String(listing.timestamp)) }}
                        </p>
                        <div v-if="listing.timestamp" class="mb-2 text-white">
                          <p>({{ formatDate(String(listing.timestamp)) }})</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Empty State -->
              <div v-else class="max-h-50">
                <div class="bg-[#05083a]">
                  <div class="px-6 py-4">
                    <p class="py-10 text-base text-center text-white">
                      No results to be displayed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
