<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import useMarketplaceItem from '~~/composables/useMarketplaceItem'

const generalSorting = [
  { name: 'ID Ascending', value: 'idAsc' },
  { name: 'ID Descending', value: 'idDesc' },
  { name: 'Lowest Price', value: 'priceAsc' },
  { name: 'Highest Price', value: 'priceDesc' },
  { name: 'Lowest Listing Quantity', value: 'listingQuantityAsc' },
  { name: 'Highest Listing Quantity', value: 'listingQuantityDsc' }
]

const currentSort = ref(generalSorting[1])
const { filterSortMarketplaceItem } = useMarketplaceItem()

const setSort = (sort) => {
  currentSort.value = sort
  filterSortMarketplaceItem(currentSort.value.value)
}
</script>

<template>
  <div class="w-auto">
    <Menu as="div" class="relative inline-block text-left">
      <MenuButton
        class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium border-2 rounded-md text-sky-400 border-sky-400 hover:bg-opacity-30"
      >
        {{ currentSort.name }}
        <ChevronDownIcon
          class="w-5 h-5 ml-2 -mr-1 text-sky-400 hover:text-violet-100"
          aria-hidden="true"
        />
      </MenuButton>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute right-0 w-40 mt-2 rounded-md bg-indigo-night focus:outline-none"
        >
          <div
            v-for="sort in generalSorting"
            :key="sort.name"
            class="px-1 py-1"
            @click="setSort(sort)"
          >
            <MenuItem>
              <span
                class="flex items-center w-full px-2 py-2 text-sm rounded-md group hover:bg-sky-400"
              >
                {{ sort.name }}
              </span>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
