import useTestnet from './useTestnet'

interface IItem {
  item_id: number
  listing_quantity: number
  floor_price: number
  type?: string
}

interface MarketplaceItemNavigationState {
  navigation?: string
}

interface IMarketplaceItemState {
  limit: number
  page: number
  sort?: string
  type?: string[] | undefined
}

interface IMarketplaceItemResponseApi {
  status: number
  data: {
    marketplace_items: IItem[]
    total_pages: number
    total_items: number
  } | null
}

const STORAGE_NAME = 'marketplaceItemm'
const DEFAULT_STATE: IMarketplaceItemState = {
  limit: 10,
  page: 1
}

const STORAGE_NAME_NAVIGATION = 'navigation'

const ROUTES = Object.freeze({
  ITEM_LIST: '/marketplace-item'
})

const useMarketplaceItem = () => {
  const router = useRouter()
  const itemState = useState<IMarketplaceItemState>(STORAGE_NAME, () => {
    return DEFAULT_STATE
  })
  const itemList = useState<IItem[]>('marketplaceItemData', () => [])
  const { apiUrl } = useTestnet()
  const totalItemsList = useState<number>(
    'marketplaceItemsNFTTotalItems',
    () => 0
  )
  const pendingItemList = useState<boolean>(
    'marketplaceItemPending',
    () => true
  )
  const pendingLoadMoreItems = useState<boolean>(
    'marketplaceItemPendingLoadMore',
    () => false
  )

  const navigationMarketplaceItemState = useState<MarketplaceItemNavigationState>(
    STORAGE_NAME_NAVIGATION,
    () => {
      return DEFAULT_STATE_NAVIGATION
    }
  )

  const clearFilterMarketplaceItem = () => {
    itemState.value = DEFAULT_STATE
  }

  const getItemList = async () => {
    pendingItemList.value = true
    const URL = apiUrl
    const params = itemState.value
    const response: IMarketplaceItemResponseApi = await $fetch(
      URL + ROUTES.ITEM_LIST,
      {
        params
      }
    )

    itemList.value = []
    if (response.data) {
      totalItemsList.value = response.data.total_items
      appendNewItems(response.data.marketplace_items)
      pendingItemList.value = false
    }
  }

  const getMoreItems = async () => {
    pendingLoadMoreItems.value = true
    itemState.value.page = itemState.value.page + 1
    const URL = apiUrl
    const params = itemState.value
    const response: IMarketplaceItemResponseApi = await $fetch(
      URL + ROUTES.ITEM_LIST,
      {
        params
      }
    )

    if (response.data) {
      totalItemsList.value = response.data.total_items
      appendNewItems(response.data.marketplace_items)
      pendingLoadMoreItems.value = false
    }
  }

  const appendNewItems = (newItems: IItem[]) => {
    newItems.forEach((marketplaceItem: IItem) => {
      itemList.value.push(marketplaceItem)
    })
  }

  const filterItemMarketplace = (item: string[]) => {
    itemState.value.type = item
    navigateItemRoute()
    getItemList()
  }

  const filterSortMarketplaceItem = (sort: string) => {
    itemState.value.sort = sort
    navigateItemRoute()
    getItemList()
  }

  const setNavigationMarketplaceItemRoute = (item: string) => {
    navigationMarketplaceItemState.value.navigation = item
  }

  const navigateItemRoute = () => {
    itemState.value.page = 1
    router.push({
      query: {
        type: itemState.value.type,
        sort: itemState.value.sort,
        page: itemState.value.page,
        limit: itemState.value.limit
      }
    })
  }

  return {
    getItemList,
    itemList,
    totalItemsList,
    pendingItemList,
    pendingLoadMoreItems,
    clearFilterMarketplaceItem,
    filterItemMarketplace,
    navigateItemRoute,
    setNavigationMarketplaceItemRoute,
    filterSortMarketplaceItem,
    getMoreItems
  }
}

export default useMarketplaceItem
