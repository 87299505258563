import {
  RaritiesMapping,
  PlayermonBodyPartLabelV2,
  PlayermonTypeMapping,
  PlayermonRarityTypeMapping
} from '../assets/utils/constant'

import useTestnet from './useTestnet'

interface MarketplaceState {
  limit: number
  page: number
  sort?: string
  classType?: string[] | undefined
  itemType?: string[] | undefined
  rarity?: string[]
  stage?: string
  swappableHeadsName?: string[]
  swappableArmsName?: string[]
  swappableEyesName?: string[]
  swappableLegsName?: string[]
  swappableChestsName?: string[]
  swappableTailsName?: string[]
  swappableBodiesName?: string[]
  minAgility?: number
  maxAgility?: number
  minIntelligence?: number
  maxIntelligence?: number
  minLuck?: number
  maxLuck?: number
  minStrength?: number
  maxStrength?: number
}

interface MarketplaceNavigationState {
  navigation?: string
}

interface Playermon {
  playermon_id: number
  price: number
  image: string
  class?: string
  head: string
  tail: string
  body: string
  arm: string
  eye: string
  chest: string
  leg: string
  timestamp?: number
}

interface StatsState {
  name: string
  value: number[]
}

interface MarketplaceResponseApi {
  status: number
  data: {
    marketplace_items: Playermon[]
    total_pages: number
    total_items: number
  } | null
}

const STORAGE_NAME = 'marketplace'
const DEFAULT_STATE: MarketplaceState = {
  limit: 10,
  page: 1
}

const STORAGE_NAME_NAVIGATION = 'navigation'
const DEFAULT_STATE_NAVIGATION: MarketplaceNavigationState = {
  navigation: 'playermon'
}

const ROUTES = Object.freeze({
  PLAYERMON_LIST: '/marketplace',
  SALE_SUMMARIES: '/marketplaceV2/saleSummaries',
  RECENT_SOLD: '/marketplaceV2/getRecentSold'
})

const useMarketplace = () => {
  const router = useRouter()
  const marketplaceState = useState<MarketplaceState>(STORAGE_NAME, () => {
    return DEFAULT_STATE
  })

  const { apiUrl } = useTestnet()

  const navigationMarketplaceState = useState<MarketplaceNavigationState>(
    STORAGE_NAME_NAVIGATION,
    () => {
      return DEFAULT_STATE_NAVIGATION
    }
  )

  const playermonList = useState<Playermon[]>('marketplaceData', () => [])
  const pending = useState<boolean>('marketplacePending', () => true)
  const pendingLoadMore = useState<boolean>(
    'marketplacePendingLoadMore',
    () => false
  )
  const totalItems = useState<number>('marketplaceTotalItems', () => 0)

  const clearFilterMarketplace = () => {
    marketplaceState.value = DEFAULT_STATE
  }

  const setNavigationMarketplaceRoute = (item: string) => {
    navigationMarketplaceState.value.navigation = item
  }

  const filterSortMarketplace = (sort: string) => {
    marketplaceState.value.sort = sort
    navigateRoute()
    loadPlayermonList()
  }

  const filterClassesMarketplace = (item: string[]) => {
    marketplaceState.value.classType = item
    navigateRoute()
    loadPlayermonList()
  }

  const filterRaritiesMarketplace = (
    rarities: Array<'Common' | 'Uncommon' | 'Rare' | 'Epic' | 'Legendary'>
  ) => {
    marketplaceState.value.rarity = rarities.map(
      rarity => RaritiesMapping[rarity]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterStageMarketplace = (stage: string) => {
    marketplaceState.value.stage = stage
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableHeadMarketplace = (heads: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableHeadsName = heads.map(
      head => PlayermonBodyPartLabelV2[head]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableTailMarketplace = (tails: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableTailsName = tails.map(
      tail => PlayermonBodyPartLabelV2[tail]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableChestMarketplace = (chests: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableChestsName = chests.map(
      chest => PlayermonBodyPartLabelV2[chest]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableEyesMarketplace = (eyes: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableEyesName = eyes.map(
      eye => PlayermonBodyPartLabelV2[eye]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableLegsMarketplace = (legs: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableLegsName = legs.map(
      leg => PlayermonBodyPartLabelV2[leg]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableArmsMarketplace = (arms: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableArmsName = arms.map(
      arm => PlayermonBodyPartLabelV2[arm]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const filterSwappableBodiesMarketplace = (bodies: PlayermonTypeMapping[]) => {
    marketplaceState.value.swappableBodiesName = bodies.map(
      body => PlayermonBodyPartLabelV2[body]
    )
    navigateRoute()
    loadPlayermonList()
  }

  const clearSwappablePartMarketplace = () => {
    delete marketplaceState.value.swappableHeadsName
    delete marketplaceState.value.swappableEyesName
    delete marketplaceState.value.swappableBodiesName
    delete marketplaceState.value.swappableChestsName
    delete marketplaceState.value.swappableArmsName
    delete marketplaceState.value.swappableLegsName
    delete marketplaceState.value.swappableTailsName
    navigateRoute()
    loadPlayermonList()
  }

  const filterStatsMarketplace = (stats: StatsState) => {
    const statsParams: Record<string, unknown> = {}
    if (stats.name === 'Agility') {
      statsParams.maxAgility = stats.value[1]
      statsParams.minAgility = stats.value[0]
    }
    if (stats.name === 'Luck') {
      statsParams.maxLuck = stats.value[1]
      statsParams.minLuck = stats.value[0]
    }
    if (stats.name === 'Strength') {
      statsParams.maxStrength = stats.value[1]
      statsParams.minStrength = stats.value[0]
    }
    if (stats.name === 'Intelligence') {
      statsParams.minIntelligence = stats.value[0]
      statsParams.maxIntelligence = stats.value[1]
    }
    if (Object.keys(statsParams).length > 0) {
      Object.assign(marketplaceState.value, statsParams)
    }
    navigateRoute()
    loadPlayermonList()
  }

  const loadPlayermonList = async () => {
    pending.value = true

    const URL = apiUrl
    const params = marketplaceState.value
    const response: MarketplaceResponseApi = await $fetch(
      URL + ROUTES.PLAYERMON_LIST,
      {
        params
      }
    )

    playermonList.value = []
    if (response.data) {
      totalItems.value = response.data.total_items
      appendNewPlayermons(response.data.marketplace_items)
      pending.value = false
    }
  }

  const loadMorePlayermon = async () => {
    pendingLoadMore.value = true
    marketplaceState.value.page = marketplaceState.value.page + 1
    const URL = apiUrl
    const params = marketplaceState.value
    const response: MarketplaceResponseApi = await $fetch(
      URL + ROUTES.PLAYERMON_LIST,
      {
        params
      }
    )

    if (response.data) {
      totalItems.value = response.data.total_items
      appendNewPlayermons(response.data.marketplace_items)
      pendingLoadMore.value = false
    }
  }

  const appendNewPlayermons = (newPlayermons: Playermon[]) => {
    newPlayermons.forEach((playermon: Playermon) => {
      playermonList.value.push(playermon)
    })
  }

  const navigateRoute = () => {
    marketplaceState.value.page = 1
    if (marketplaceState.value.rarity) {
      router.push({
        query: {
          classType: marketplaceState.value.classType,
          itemType: marketplaceState.value.itemType,
          rarities: marketplaceState.value.rarity.map(
            rarity => PlayermonRarityTypeMapping[rarity]
          ),
          sort: marketplaceState.value.sort,
          page: marketplaceState.value.page,
          limit: marketplaceState.value.limit,
          stage: marketplaceState.value.stage,
          minIntelligence: marketplaceState.value.minIntelligence,
          maxIntelligence: marketplaceState.value.maxIntelligence,
          minAgility: marketplaceState.value.minAgility,
          maxAgility: marketplaceState.value.maxAgility,
          minStrength: marketplaceState.value.minStrength,
          maxStrength: marketplaceState.value.maxStrength,
          minLuck: marketplaceState.value.minLuck,
          maxLuck: marketplaceState.value.maxLuck,
          head: marketplaceState.value.swappableHeadsName,
          body: marketplaceState.value.swappableBodiesName,
          eyes: marketplaceState.value.swappableEyesName,
          chest: marketplaceState.value.swappableChestsName,
          arm: marketplaceState.value.swappableArmsName,
          tail: marketplaceState.value.swappableTailsName,
          leg: marketplaceState.value.swappableLegsName
        }
      })
    } else {
      router.push({
        query: {
          classType: marketplaceState.value.classType,
          itemType: marketplaceState.value.itemType,
          sort: marketplaceState.value.sort,
          page: marketplaceState.value.page,
          limit: marketplaceState.value.limit,
          stage: marketplaceState.value.stage,
          minIntelligence: marketplaceState.value.minIntelligence,
          maxIntelligence: marketplaceState.value.maxIntelligence,
          minAgility: marketplaceState.value.minAgility,
          maxAgility: marketplaceState.value.maxAgility,
          minStrength: marketplaceState.value.minStrength,
          maxStrength: marketplaceState.value.maxStrength,
          minLuck: marketplaceState.value.minLuck,
          maxLuck: marketplaceState.value.maxLuck,
          head: marketplaceState.value.swappableHeadsName,
          body: marketplaceState.value.swappableBodiesName,
          eyes: marketplaceState.value.swappableEyesName,
          chest: marketplaceState.value.swappableChestsName,
          arm: marketplaceState.value.swappableArmsName,
          tail: marketplaceState.value.swappableTailsName,
          leg: marketplaceState.value.swappableLegsName
        }
      })
    }
  }

  return {
    marketplaceState,
    navigationMarketplaceState,
    playermonList,
    pending,
    pendingLoadMore,
    totalItems,
    clearFilterMarketplace,
    setNavigationMarketplaceRoute,
    filterSortMarketplace,
    filterClassesMarketplace,
    filterRaritiesMarketplace,
    loadPlayermonList,
    loadMorePlayermon,
    filterStageMarketplace,
    filterSwappableHeadMarketplace,
    filterSwappableTailMarketplace,
    filterSwappableChestMarketplace,
    filterSwappableEyesMarketplace,
    filterSwappableLegsMarketplace,
    filterSwappableArmsMarketplace,
    filterSwappableBodiesMarketplace,
    clearSwappablePartMarketplace,
    filterStatsMarketplace
  }
}

export default useMarketplace
